async function getSearchSuggestions(inputValue, type, category = null) {
  let lookupUrl = [`/api/autocomplete/${type}`, inputValue].join('?q=');
  if (category && category !== 'ALL') {
    lookupUrl = `${lookupUrl}&c=${category}`
  }
  try {
    const response = await fetch(lookupUrl);
    if (!response.ok) {
      return [];
    }
    const json = await response.json();
    return json.data;
  } catch (error) {
    return [];
  }
}

export default getSearchSuggestions;
